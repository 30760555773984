
import React from "react"
import imgLogo from "../images/Rabbit Logo.png"

import "./logoBanner.css"

function LogoBanner() {
    return (
        <img id="logo-banner" src={imgLogo} alt="Rabbit logo" />
    );
}

export default LogoBanner