import React, { useState } from 'react';
import API from '@aws-amplify/api-graphql';
import * as mutations from '../graphql/mutations';

import './formRegisterInterest.css';

function FormRegisterInterest() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [role, setRole] = useState('Homeowner');
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [comment, setComment] = useState();

  function onRoleChange(e) {
    setRole(e.target.value);
  }

  function onNameChange(e) {
    setName(e.target.value);
  }

  function onEmailChange(e) {
    setEmail(e.target.value);
  }

  function onPhoneChange(e) {
    setPhone(e.target.value);
  }

  function onCommentChange(e) {
    setComment(e.target.value);
  }

  async function onClickSubmit(e) {
    if (isProcessing || isSubmitted) { return; }
    setIsProcessing(true);
    const submitItem = { name, role, email, phone, comment };
    console.log(submitItem);
    const newInterest = await API.graphql({ query: mutations.createInterestRegister, variables: {input: submitItem}});
    if (newInterest.data && newInterest.data.createInterestRegister) { 
      setIsSubmitted(true);
      setName();
      setEmail();
      setPhone();
      setRole();
      setComment();
    }
    setIsProcessing(false);
  }

  return (
    <div id="interest-form">
      <p>
        {isSubmitted 
          ? 'Thank you for your interest. Watch this space!'
          : 'Register your interest here:'
        }
      </p>
      <div id="interest-form-grid" style={{visibility: (isSubmitted ? 'hidden' : null)}}>
        <label htmlFor="interest-form-role">I am a</label>
        <select disabled={isProcessing} id="interest-form-role" onBlur={onRoleChange} required>
          <option value="Homeowner">Homeowner</option>
          <option value="Renter">Renter</option>
          <option value="Cleaner (sole)">Cleaner (sole)</option>
          <option value="Cleaner (business)">Cleaner (business)</option>
        </select>
        <label htmlFor="interest-form-name">Name</label><input id="interest-form-name" disabled={isProcessing} type="text" onChange={onNameChange}/>
        <label htmlFor="interest-form-email">Email</label><input id="interest-form-email" disabled={isProcessing} type="email" onChange={onEmailChange} required/>
        <label htmlFor="interest-form-phone">Phone</label><input id="interest-form-phone" disabled={isProcessing} type="phone" onChange={onPhoneChange} />
        <label htmlFor="interest-form-comment">Comment</label><input id="interest-form-comment" disabled={isProcessing} type="text" onChange={onCommentChange} />
      </div>
      <p>
        <button id="interest-submit" style={{visibility: (isSubmitted ? 'hidden' : null)}} disabled={isProcessing || isSubmitted} onClick={onClickSubmit}>Submit</button>           
      </p>
    </div>
  );
  
}

export default FormRegisterInterest;
