import React from "react"

import LayoutFixedCenter from "../components/layoutFixedCenter"
import FormRegisterInterest from "../components/formRegisterInterest"
import LogoBanner from "../components/logoBanner"
import SEO from "../components/seo"

const IndexPage = () => (
  <LayoutFixedCenter>
    <SEO title="Home" />
    <div className="App">
      <header className="App-header">
        <LogoBanner />
        <h3 className="bold" style={{marginBottom: '0px'}}>
          Coming in 2021:
        </h3>
        <p style={{marginTop: '0px'}}>
          A revolutionary new way to clean your home.
        </p>
      </header>
      <FormRegisterInterest />
    </div>
  </LayoutFixedCenter>
)

export default IndexPage
