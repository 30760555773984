/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createInterestRegister = /* GraphQL */ `
  mutation CreateInterestRegister(
    $input: CreateInterestRegisterInput!
    $condition: ModelInterestRegisterConditionInput
  ) {
    createInterestRegister(input: $input, condition: $condition) {
      id
      name
      phone
      email
      role
      comment
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateInterestRegister = /* GraphQL */ `
  mutation UpdateInterestRegister(
    $input: UpdateInterestRegisterInput!
    $condition: ModelInterestRegisterConditionInput
  ) {
    updateInterestRegister(input: $input, condition: $condition) {
      id
      name
      phone
      email
      role
      comment
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteInterestRegister = /* GraphQL */ `
  mutation DeleteInterestRegister(
    $input: DeleteInterestRegisterInput!
    $condition: ModelInterestRegisterConditionInput
  ) {
    deleteInterestRegister(input: $input, condition: $condition) {
      id
      name
      phone
      email
      role
      comment
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
