/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "./layoutBase.css"

import Amplify from '@aws-amplify/core'
import aws_exports from '../aws-exports';
Amplify.configure(aws_exports);

const LayoutBase = ({ children }) => {
  return (
    <>
        {process.env.NODE_ENV !== 'production' ?
          <p id="devFlag">
            {process.env.NODE_ENV}
          </p>
        : null}
        <div id="layout-base">{children}</div>
    </>
  )
}

LayoutBase.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutBase
